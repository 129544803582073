'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:holidayModal
 * @description Shows a modal to manage a holiday.
 */
angular.module('diceApp')
  .component('holidayModal', {
    bindings: {
      holiday: '<',
      onSave: '<',
      modal: '<'
    },
    templateUrl: 'es6/period/holiday/holiday.modal.html',
    controllerAs: 'holidayController',
    controller: function (Holiday, message) {
      const holidayController = this;

      holidayController.close = function () {
        holidayController.modal.close();
      };

      holidayController.save = function () {
        Holiday.store(holidayController.holiday).$promise.then((result) => {
          message.addSuccessLabel('Message.Save.Success');
          holidayController.onSave(result);
          holidayController.close();
        });
      };
    }
  });
