'use strict';

/**
 * @ngdoc function
 * @name diceApp.component:dicePeriodProgress
 * @description Shows the progress of each period.
 */
angular.module('diceApp')
  .component('dicePeriodProgress', {
    templateUrl: 'es6/period/period.progress.html',
    controllerAs: 'periodProgressController',
    controller: function (Period) {
      const periodProgressController = this;

      periodProgressController.$onInit = function () {
        periodProgressController.loading = true;

        Period.progress().$promise.then((periods) => {
          periodProgressController.periods = periods;
        }).finally(() => {
          periodProgressController.loading = false;
        });
      };
    }
  });
