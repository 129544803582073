'use strict';

/**
 * @ngdoc function
 * @name diceApp.factory:Holiday
 * @description
 * # Holiday
 * Holiday resource of the diceApp
 */
angular.module('diceApp')
  .factory('Holiday', function (DiceResource) {
    return DiceResource('/holidays');
  });
